import { mapGetters, mapActions } from 'vuex'
import { usp, partners } from '@/constant'
import { scrollToTheTarget, showVueToast } from '@/utils'
import cookies from 'js-cookie'
import dayjs from 'dayjs'
export default {
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  components: {
    // NEW
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Corporate: () => import(/* webpackChunkName: "icon-home" */ '@/components/icons/CorporateSolutions'),
    Education: () => import(/* webpackChunkName: "icon-home" */ '@/components/icons/EduPlatforms'),
    Personal: () => import(/* webpackChunkName: "icon-home" */ '@/components/icons/PersonalGrowth'),
    Alumni: () => import(/* webpackChunkName: "icon-home" */ '@/components/icons/Alumni'),
    ClassesHeld: () => import(/* webpackChunkName: "icon-home" */ '@/components/icons/ClassesHeld'),
    CorporateTraining: () => import(/* webpackChunkName: "icon-home" */ '@/components/icons/CorporateTraining'),
    Project: () => import(/* webpackChunkName: "icon-home" */ '@/components/icons/Project'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    School: () => import(/* webpackChunkName: "school" */ '@/components/icons/School'),
    CreditCard: () => import(/* webpackChunkName: "credit-card" */ '@/components/icons/CreditCard'),
    Cash: () => import(/* webpackChunkName: "cash" */ '@/components/icons/Cash'),
    Rocket: () => import(/* webpackChunkName: "rocket" */ '@/components/icons/Rocket'),
    Run: () => import(/* webpackChunkName: "run" */ '@/components/icons/Run'),
    // OLD
    Carousel: () => import(/* webpackChunkName: "carousel" */ '@/components/carousel/Carousel'),
    Loading: () => import(/* webpackChunkName: "illustration-loading" */ '@/components/illustration/Loading'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    ProductCard: () => import(/* webpackChunkName: "productcard" */ '@/components/card/ProductCard'),
    TestimonyCard: () => import(/* webpackChunkName: "testimonycard" */ '@/components/card/TestimonyCard'),
    NewTestimonyCard: () => import(/* webpackChunkName: "accordion" */ '@/components/card/NewTestimonyCard'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal')
  },
  data() {
    return {
      // NEW
      paymentMethod: ['CASH', 'PAYASYOUGO', 'ISA', 'SCHOLARSHIP', 'INSTALLMENT', 'CREDITCARD'],
      // OLD
      loading: false,
      contentVisible: false,
      carouselMainBannerOptions: {
        type: 'loop',
        autoplay: true,
        arrows: false,
        perPage: 1,
        speed: 1000,
        interval: 5000,
        pagination: true,
        gap: '0rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1
          }
        }
      },
      mainBanners: [
        {
          id: '0',
          name: '',
          seqNo: 3,
          slug: '/prakerja/redeem',
          title: 'banner prakerja',
          url: require('@/assets/images/banner-prakerja.webp')
        },
        {
          id: '1',
          name: '',
          seqNo: 1,
          slug: '/class/regular/devsecops-eng',
          title: 'banner devsecops',
          url: require('@/assets/images/banner-devsecops.jpeg')
        },
        {
          id: '2',
          name: '',
          seqNo: 2,
          slug: '/class/regular/digital-risk-protection',
          title: 'banner digital protection',
          url: require('@/assets/images/banner-digital-protection.jpeg')
        },
        {
          id: '4',
          name: '',
          seqNo: 4,
          slug: '/class/regular/vsoc-analyst',
          title: 'banner vsoc',
          url: require('@/assets/images/banner-vsoc.jpeg')
        }
      ],
      carouselBannerStyle: 'pb-20',
      carouselBannerOptions: {
        type: 'loop',
        autoplay: true,
        arrows: false,
        perPage: 2,
        speed: 1000,
        interval: 5000,
        pagination: true,
        gap: '2rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1
          }
        }
      },
      carouselOfferOptions: {
        type: 'loop',
        autoplay: true,
        arrows: false,
        perPage: 4,
        interval: 5000,
        pagination: true,
        speed: 1000,
        gap: '2.5rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1
            // fixedWidth: '256px'
          }
        }
      },
      carouselTestimonyOptions: {
        type: 'loop',
        autoplay: true,
        arrows: false,
        perPage: 2,
        speed: 1000,
        pagination: true,
        interval: 5000,
        gap: '1rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1
          }
        }
      },
      carouselPartnersOptions: {
        type: 'loop',
        autoplay: true,
        arrows: false,
        perPage: 5,
        speed: 1000,
        pagination: true,
        interval: 5000,
        gap: '2rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1
          }
        }
      },
      carouselPaymentOptions: {
        type: 'loop',
        autoplay: true,
        arrows: false,
        perPage: 4,
        speed: 1000,
        gap: '0.75rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1,
            fixedWidth: '290px'
          }
        }
      },
      email: '',
      name: '',
      noHp: '',
      password: '',
      selectedMotivation: '',
      motivationList: ['Getting a job in Tech Industries', 'Switching careers into Tech', 'Gaining new knowledge (upskilling)', 'Prakerja', 'Others'],
      observedElement: null,
      showCompanyVideo: false,
      selectedTestimony: null,
      visibleTestimonyModal: false,
      isPopUpAdsVisible: null,
      popUpContent: {
        title: '',
        description: '',
        image: '',
        primaryBtn: '',
        secondaryBtn: '',
        path: '',
        startDate: '',
        endDate: '',
        updatedDate: ''
      }
    }
  },
  computed: {
    ...mapGetters('home', ['homeContent']),
    ...mapGetters('common', ['isMobile', 'adminB2C', 'adminGeneral']),
    baseDomain() {
      return process.env.VUE_APP_BASE_DOMAIN
    },
    adsVisited() {
      return cookies.get('adsVisited', { domain: this.baseDomain, expires: 365 })
    },
    checkDuration() {
      if (this.popUpContent.startDate === '' || this.popUpContent.endDate === '') return null
      return this.checkDifferenceTime(this.popUpContent.startDate, this.popUpContent.endDate)
    },
    durationDifference() {
      if (this.checkDuration === null) return null
      return this.checkDifferenceTime(dayjs(), this.popUpContent.startDate)
    },
    duration() {
      if (this.checkDuration === null || this.checkDuration < this.durationDifference) return null
      const result = this.checkDuration - this.durationDifference
      if (result === 0) return 1
      return result
    },
    activeLanguange() {
      return this.$i18n.locale
    },
    bilingualPayment() {
      return this.$i18n.messages[this.activeLanguange].programDetails.payment
    },
    bilingualHeroContent() {
      return this.$i18n.messages[this.activeLanguange].home.hero.content
    },
    contructedPaymentMethod() {
      return this.paymentMethod.map((payment) => {
        return {
          title: this.bilingualPayment[payment].title,
          desc: this.bilingualPayment[payment].desc
        }
      })
    },
    contructedHeroContent() {
      return [
        {
          title: this.bilingualHeroContent.techEdu.title,
          // eslint-disable-next-line quotes
          desc: this.bilingualHeroContent.techEdu.desc,
          buttonText: this.bilingualHeroContent.techEdu.button,
          colorText: 'text-yellow',
          buttonStyle: 'bg-yellow text-white border-yellow hover:opacity-50',
          url: '/programs'
        },
        {
          title: this.bilingualHeroContent.solutions.title,
          desc: this.bilingualHeroContent.solutions.desc,
          buttonText: this.bilingualHeroContent.solutions.button,
          colorText: 'text-purple',
          buttonStyle: 'bg-purple text-white border-purple hover:opacity-50',
          url: '/corporate-solutions'
        },
        {
          title: this.bilingualHeroContent.platforms.title,
          desc: this.bilingualHeroContent.platforms.desc,
          buttonText: this.bilingualHeroContent.platforms.button,
          colorText: 'text-tosca',
          buttonStyle: 'bg-tosca text-white border-tosca hover:opacity-50',
          url: '/products/lms'
        }
      ]
    },
    constructedTestimonies() {
      return this.homeContent.alumniHomePages.map((item) => {
        return {
          testimony: {
            userPhoto: item.url,
            userName: item.name,
            message: item.paragraph,
            class: item.kelas
          }
        }
      })
    },
    uspList() {
      return usp
    },
    partnerList() {
      return partners
    },
    filteredOurPrograms() {
      return this.homeContent?.trainingHomePage?.trainingItemHomePages.filter((item) => item.title !== '') || []
    },
    videoLink() {
      return this.homeContent?.youTubeLinkHomePages && this.homeContent.youTubeLinkHomePages[0].url
    },
    utm() {
      return this.$route.query.utm_source || 'website'
    },
    homeBanner() {
      return this.homeContent?.slideBannerHomePages || []
    },
    homeTestimony() {
      return this.homeContent.alumniHomePages || []
    },
    homeBannerLimit() {
      return this.isMobile ? 1 : 2
    },
    homeTestimonyLimit() {
      return this.isMobile ? 1 : 3
    },
    filteredOurProgramsLimit() {
      return this.isMobile ? 1 : 4
    }
  },
  created() {
    this.getContent()
    this.checkPopUpContent()
  },
  mounted() {
    this.observedElement = document.getElementById('company-video')
    if (this.observedElement) {
      this.createObserver(this.observedElement)
    }
  },
  methods: {
    ...mapActions('home', ['getHomeContent', 'getPopUpContent']),
    ...mapActions('auth', ['registerStudent']),
    ...mapActions('common', ['showLoading', 'hideLoading', 'sendWhatsApp']),
    toSelectedPage() {
      this.isPopUpAdsVisible = false
      cookies.set('adsVisited', 3, { expires: this.duration })
      this.$router.push({ path: this.popUpContent.path })
    },
    toRedeemPrakerja() {
      this.$router.push('/prakerja/redeem')
    },
    toPanduanRedeem() {
      this.$router.push('/prakerja/panduan')
    },
    addVisitedData() {
      this.isPopUpAdsVisible = false
      let adsVisited = parseInt(this.adsVisited)
      adsVisited++
      cookies.set('adsVisited', adsVisited, { expires: this.duration })
    },
    checkPopUpContent() {
      this.getPopUpContent({}).then(
        (res) => {
          if (res.data.code === 404) return cookies.remove('adsVisited')
          const popUp = res.data.data

          this.popUpContent = {
            title: popUp.title,
            description: popUp.description,
            image: popUp.image,
            primaryBtn: popUp.labelBtn1,
            secondaryBtn: popUp.labelBtn2,
            path: popUp.toUrl,
            startDate: popUp.startDate,
            endDate: popUp.endDate,
            updatedDate: popUp.updatedDate
          }

          this.checkCookie()
        },
        () => {}
      )
    },
    checkDifferenceTime(startDate, endDate) {
      return Math.abs(dayjs(startDate, 'YYYY-MM-DD').startOf('day').diff(dayjs(endDate, 'YYYY-MM-DD').startOf('day'), 'days'))
    },

    checkCookie() {
      if (this.duration === null) return
      const adsVisited = cookies.get('adsVisited', { domain: this.baseDomain })
      if (isNaN(adsVisited)) {
        cookies.set('adsVisited', 0, { expires: this.duration })
      }

      const cookiesAdsUpdated = cookies.get('_ads_updated', { domain: this.baseDomain })
      if (cookiesAdsUpdated === undefined || cookiesAdsUpdated === '') {
        // store data updatedDate and set visible true if empty cookies
        cookies.set('_ads_updated', this.popUpContent.updatedDate)
        this.isPopUpAdsVisible = true
        return
      }

      if (adsVisited >= 2) {
        // if updatedDate and cookies different then store new data and reset adsVisited
        if (cookiesAdsUpdated !== this.popUpContent.updatedDate) {
          cookies.set('_ads_updated', this.popUpContent.updatedDate)
          cookies.set('adsVisited', 0, { expires: this.duration })
          this.isPopUpAdsVisible = true
        } else {
          this.isPopUpAdsVisible = false
        }
      } else {
        this.isPopUpAdsVisible = true
      }
    },
    showDetail(item) {
      this.selectedTestimony = item
      this.toggleTestimonyModal()
    },
    toggleTestimonyModal() {
      this.visibleTestimonyModal = !this.visibleTestimonyModal
    },
    handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.showCompanyVideo = true
          observer.unobserve(this.observedElement)
        }
      })
    },
    goToDetail(item) {
      if (item.title === 'Cash') {
        this.sendWhatsApp({ number: this.adminB2C, topic: 'Pembiayaan Kelas Secara Cash' })
      } else if (item.title === 'Credit Card' || item.title === 'Kartu Kredit') {
        this.sendWhatsApp({ number: this.adminB2C, topic: 'Pembiayaan Kelas Via Kartu Kredit' })
      } else if (item.title === 'ISA') {
        this.$router.push('/isa')
      } else if (item.title === 'Scholarship' || item.title === 'Beasiswa') {
        this.$router.push('/tech-champ-scholarship')
      } else if (item.title === 'Installment' || item.title === 'Cicilan') {
        this.$router.push('/installment')
      } else if (item.title === 'PAYASYOUGO' || item.title === 'Pay As You Go') {
        this.sendWhatsApp({ number: this.adminB2C, topic: 'Pembiayaan Kelas Secara Pay As You Go' })
      }
    },
    createObserver(el) {
      const options = {
        root: null,
        threshold: '0'
      }
      const observer = new IntersectionObserver(this.handleIntersect, options)
      observer.observe(el)
    },
    getContent() {
      this.getHomeContent({}).then((res) => {
        if (this.homeBanner.length > this.homeBannerLimit) {
          this.carouselBannerOptions.pagination = true
          this.isMobile ? (this.carouselBannerStyle = 'pb-8') : (this.carouselBannerStyle = 'pb-16')
        } else {
          this.carouselBannerOptions.pagination = false
          this.isMobile ? (this.carouselBannerStyle = 'pb-10') : (this.carouselBannerStyle = 'pb-20')
        }
        // this.homeTestimony.length > this.homeTestimonyLimit ? (this.carouselTestimonyOptions.pagination = true) : (this.carouselTestimonyOptions.pagination = false)
        this.filteredOurPrograms.length > this.filteredOurProgramsLimit ? (this.carouselOfferOptions.pagination = true) : (this.carouselOfferOptions.pagination = false)
        this.contentVisible = true
      })
    },
    goTo(target) {
      scrollToTheTarget(target, -100)
    },
    testingMethod() {
      this.loading = true
    },
    callback(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-visible')
        } else {
          // entry.target.classList.remove('is-visible')
        }
      })
    },
    counter(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !entry.target.classList.contains('count-visible')) {
          entry.target.classList.add('count-visible')
          const counters = document.querySelectorAll('.counter')
          for (const n of counters) {
            const updateCount = () => {
              const target = +n.getAttribute('data-target')
              const count = +n.innerText
              const speed = 500
              const inc = target / speed
              if (count < target) {
                n.innerText = Math.ceil(count + inc)
                setTimeout(updateCount, 1)
              } else {
                n.innerText = target
              }
            }
            updateCount()
          }
        }
      })
    },
    doRegister() {
      if (this.validateForm()) {
        this.showLoading()
        const payloads = {
          email: this.email,
          name: this.name,
          nohp: this.noHp,
          password: this.password,
          motivation: this.selectedMotivation,
          utm: this.utm
        }

        this.registerStudent({
          payloads: payloads
        }).then(
          () => {
            this.refreshInput()
            this.hideLoading()
            showVueToast('Account Registration is Succesfully!', 'success', 3000)
          },
          (err) => {
            this.hideLoading()
            showVueToast('Account Registration is Failed! ' + err.response.data.errors.error, 'error', 3000)
          }
        )
      }
    },
    refreshInput() {
      this.email = ''
      this.name = ''
      this.noHp = ''
      this.password = ''
      this.selectedMotivation = ''
    },
    validateForm() {
      // eslint-disable-next-line
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      const pattern = /^[0-9]{10,13}$/
      if (this.name === '') {
        showVueToast('Name is required!', 'error', 3000)
        return false
      } else if (this.email === '') {
        showVueToast('Email is required!', 'error', 3000)
        return false
      } else if (!regex.test(this.email)) {
        showVueToast('Email format is invalid!', 'error', 3000)
        return false
      } else if (this.noHp === '') {
        showVueToast('Phone number is required!', 'error', 3000)
        return false
      } else if (!pattern.test(this.noHp)) {
        showVueToast('Your phone number format is invalid! (ex: 081234567890)', 'error', 3000)
        return false
      } else if (this.password === '') {
        showVueToast('Password is required!', 'error', 3000)
        return false
      } else {
        return true
      }
    }
  }
}
